import { Helmet } from 'react-helmet-async';
import { Link, useSearchParams } from 'react-router-dom';
// @mui
import {
    Container,
    Button,
    Typography,
    Grid,
    Card,
    ListItem,
    ListItemText
} from '@mui/material';

import WestIcon from '@mui/icons-material/West';

// IMAGEN
import logo from '../../../assets/images/logos/logo_scorpion.png';

// CONTEXT
import useTicket from '../../../hooks/useTicket';

const {
    REACT_APP_API_URL_IMAGES
} = process.env;

export default function PesosLealtadMicroSitio() {
    const { ticket } = useTicket();

    return (
        <div>
            <Helmet>
                <title> Pesos Lealtad | Scorpion </title>
            </Helmet>

            <Container maxWidth="lg" style={{ marginTop: '30px', marginBottom: '30px' }}>
                <Grid container justifyContent="center">
                    <img src={logo} alt="scorpion" height="140" />
                </Grid>

                <Grid container justifyContent="start" style={{ marginTop: '30px' }}>
                    <Grid item>
                        <Button
                            variant='contained'
                            component={Link}
                            to={'/micro-sitio/mas-promociones'}
                            color='success'
                            startIcon={<WestIcon />}
                        >
                            Regresar
                        </Button>
                    </Grid>
                </Grid>
                
                <Typography variant='h3' align='center' mt={2} mb={3}>PESOS LEALTAD</Typography>

                {
                    ticket.pesosLealtad.marcas.map((marca, index) => (
                        <div key={index}>
                            <Card sx={{ p: 3, boxShadow: 18, borderRadius: 4, mb: 3 }}>
                                <Grid display="flex" justifyContent="center">
                                    <Typography variant='h6' style={{ fontWeight: 'bold' }}>{marca.nombreProveedor}</Typography>
                                </Grid>

                                <Grid display="flex" justifyContent="space-around" alignItems="center">
                                    <Grid>
                                        <img
                                            src={`${REACT_APP_API_URL_IMAGES}${marca.urlImagen}`}
                                            alt=""
                                            width="100" height="100"
                                        />
                                    </Grid>
                                    <Grid>
                                        <ListItem>
                                            <ListItemText 
                                                primary={<Typography variant='subtitle1' style={{ fontWeight: 'bold' }}>Compra Anterior</Typography>}
                                                secondary={<Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'right', color: '#feb02a' }}>${Number(marca.compraAnterior).toFixed(2)}</Typography>}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText 
                                                primary={<Typography variant='subtitle1' style={{ fontWeight: 'bold' }}>Tu Compra este Mes</Typography>}
                                                secondary={<Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'right', color: '#feb02a' }}>${Number(marca.compraEsteMes).toFixed(2)}</Typography>} />
                                        </ListItem>
                                    </Grid>
                                    <Grid>
                                        <ListItem>
                                            <ListItemText 
                                                primary={<Typography variant='subtitle1' style={{ fontWeight: 'bold' }}>Objetivo de Compra</Typography>}
                                                secondary={<Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'right', color: '#feb02a' }}>${Number(marca.objetivosCompra).toFixed(2)}</Typography>} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText 
                                                primary={<Typography variant='subtitle1' style={{ fontWeight: 'bold' }}>Pesos Generados</Typography>}
                                                secondary={<Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'right', color: '#feb02a' }}>${Number(marca.pesosGenerados).toFixed(2)}</Typography>} />
                                        </ListItem>
                                    </Grid>
                                </Grid>
                            </Card>
                        </div>
                    ))
                }

                {
                    Object.keys(ticket.pesosLealtad.marcas).length === 0 && (
                        <div>
                            <Card sx={{ p: 3, boxShadow: 18, borderRadius: 4, mb: 3 }}>
                                <Grid display="flex" justifyContent="center">
                                    <Typography variant='h3' style={{ fontWeight: 'bold', textAlign: 'center' }}>No tienes promociones de Pesos de Lealtad</Typography>
                                </Grid>
                            </Card>
                        </div>
                    )
                }
            </Container>
        </div>
    );
}
